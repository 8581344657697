<template>

  <div class="app de">
    <div class="hd">
      <span @click="$router.back()">返回</span>
      <div>用户登录</div>
      <div></div>

    </div>
    <div class="ct">
      <md-field>
        <md-input-item
            ref="name"
            preview-type="text"
            v-model="user.phone"
            title="用户手机号码"
            type="phone"
            placeholder="请填写手机号码"
            is-title-latent
        ></md-input-item>
        <md-input-item
            ref="id"
            type="password"
            preview-type="text"
            title="密码"
            v-model="user.pwd"
            placeholder="请填写密码"
            is-title-latent
        ></md-input-item>

      </md-field>
      <md-button type="primary" @click="dologin">立即登录</md-button>
    </div>

  </div>

</template>

<script>
export default {
  name: "login",
  data(){
    return {
      user:{
        phone:'',
        pwd:'',
        repwd:''
      }
    }
  },
  methods:{
    async dologin(){
      if(!this.user.phone || this.user.phone.length!==11) return this.$toast.info('请填写正确的手机号码')
      const res = await this.$api.post('/mapi/login',{ phone:this.user.phone,pwd:this.user.pwd})
      const { success,info,data } = res.data;
      if(success){
        this.$toast.succeed('登录成功')
        this.$store.commit('setUserInfo',data);
        this.$router.back()
      }else{
        this.$toast.failed(info)
      }


    }
  }

}
</script>

<style scoped>

</style>
